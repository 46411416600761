const phrases = [
  "Кто у нас тут самая несчастная зайка? Иди скорее обниму :)",
  "Ничего, зайка, ничего, завтра все будет намного лучше!",
  "Ты мой бедный зайка!",
  "Ты ж моя хорошая, не надо грустить!",
  "Ты моя красавица! Не грусти, всё пройдёт.",
  "Кто мой пушистый котеночек? Иди скорее обниму :) Всё будет хорошо!",
  "Не расстраивайся, всё пройдёт!",
  "Ну как же так можно, и за что тебе все это? (((",
  "Бедненькая моя девочка! Иди я тебя пожалею.",
  "Моя ты бедненькая, как же тебе тяжело((",
  "Все будет хорошо! Выпей горячего чаю и не забудь обо всех своих бедах, все образуется.",
  "Ты моя умница! Не расстраивайся, завтра всё будет хорошо.",
  "Ай-яй-яй, ну как же так (((",
  "У кошки боли, у собаки боли, а у заиньки пусть все пройдет!",
  "Не расстраивайся, маленький мой ангелочек!",
  "И чьи такие красивые глазки? А ну-ка не грустить!",
  "Все равно ты у меня самая лучшая! Иди под крылышко, жалеть тебя буду.",
  "Это просто такой день. Все наладится, солнце, вот увидишь!",
  "Бедная ты моя, несчастная! Ничего, всё будет хорошо.",
]
export default phrases

